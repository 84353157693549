'use client'
import "@general/styles/components/not_found.scss"
import Image from 'next/image'
import logoLong from '@general/assets/logo_long.svg'
import { useTranslation } from "@general/i18n/client";
import { RootState } from "@general/redux/store";
import { useSelector } from "react-redux";
import Link from "next/link";
import { useState } from "react";
import { ContactFormModal } from "@general/components/client_side/ContactFormModal";

export function NotFound(): JSX.Element {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'common')
    const [modalContactFormShow, setModalContactFormShow] = useState(false);
    return <>
        <ContactFormModal
            show={modalContactFormShow}
            onHide={() => setModalContactFormShow(false)}
            place_from="not_found"
        />
        <div className="not-found">
            <div className="content">
                <Link href={'/' + selectedLang} title={t('Home WM Web Agency')}>
                    <Image
                        className="header_logo"
                        alt={t('Logo WM Web Agency')}
                        src={logoLong.src}
                        width={131}
                        height={50}
                    />
                </Link>
                <div className="title">
                    {t('Page not found')}
                </div>
                <div className="code_number">
                    404
                </div>
                <div className="description">
                    {t(`The page requested couldn't be found - this could be due to a spelling error in the URL or a removed page.`)}
                </div>
                <button className='btn btn-dark' onClick={() => setModalContactFormShow(true)}>{t('Contuct us')}</button>
            </div>
        </div>
    </>
}